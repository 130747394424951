<script>

export default {
  name: 'Steps',
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

<template>
  <div class="mt20 ml10">
    <div v-for="(item,index) in list" :key="index" class="steps">
      <div v-if="index === 0">
        <div class="steps-node">
          <div class="img-body">
            <img alt="" src="@/assets/flow/present.png" style="width: 16px;">
          </div>
          提交申请
        </div>
        <div class="line" />
      </div>
      <div v-else>
        <div class="steps-node">
          <div class="img-body">
            <img v-if="item.icon === 'ok'" alt="" src="@/assets/flow/ok.png" style="width: 16px;">
            <img v-if="item.icon === 'no'" alt="" src="@/assets/flow/no.png" style="width: 16px;">
            <img v-if="item.icon === 'wait'" alt="" src="@/assets/flow/wait.png" style="width: 16px;">
            <img v-if="item.icon === 'loading'" alt="" src="@/assets/flow/loading.png" style="width: 16px;">
          </div>
          {{ item.type }}
        </div>
        <div class="line" />
      </div>
      <div v-if="item.userList.length > 0" class="steps-body">
        <div v-for="(value, ind) in item.userList" :key="ind" class="steps-body-item">
          <div class="steps-body-item-name">
            <span>{{ value.name }}</span>
            <span style="color: rgba(0,0,0,0.45);">{{ value.time }}</span>
          </div>
          <div class="fs14 steps-body-item-status mt2">{{ value.processStatus }}</div>
          <div v-if="value.opinion && index !== 0" class="steps-body-item-opinion mt4">审批意见:  {{ value.opinion }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.steps {
  list-style: none;
  box-sizing: border-box;
  padding-left: 20px;
  position: relative;
  margin-bottom: 16px;

  .line {
    width: 1px;
    height: 100%;
    background-color: #e6e6e6;
    position: absolute;
    top: 11px;
    bottom: 0;
    left: -1px;
  }

  &-node {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 22px;

    .img-body {
      width: 20px;
      height: 20px;
      padding: 2px;
      background-color: #FFFFFF;
      color: #fff;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 2;
      left: 0;
      top: 11px;
      transform: translate(-50%, -50%);
    }
  }

  &:last-child .line {
    display: none;
  }

  &-body {
    margin-top: 8px;
    background: #F7F9FB;
    border-radius: 4px;
    padding: 16px;

    &-item {
      &-name {
        display: flex;
        gap: 8px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.85);
      }

      &-status {
        color: #30A46C;
      }

      &-opinion {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.85);
      }
    }
  }
}

</style>
