import OSS from 'ali-oss'
import http from '@flowHttp'
import moment from 'moment'

function fetchToken(bucket) {
  return new Promise((resolve, reject) => {
    http
      .get('/base/aliOSSToken', { bucket })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export class AliOss {
  constructor() {
    this.client = null // 实体
    this.bucket = '' // 存储空间
    this.refreshSTSTokenInterval = 300000 // 刷新token时间间隔
    this.alioss = { // 配置文件
      region: '',
      accessKeyId: '',
      accessKeySecret: '',
      stsToken: '',
      bucket: '',
      refreshSTSTokenInterval: 300000,
    }
    this.options = {
      parallel: 2, // 分片上传并发数量
      partSize: 1024 * 1024, // 分片大小，默认1mb, 最小1024kb
      timeout: 120000, // 设置超时时间
    }
  }

  async init(bucket) {
    if (this.client?.stsTokenFreshTime) {
      if (moment().isBefore(moment(this.client.stsTokenFreshTime).add(this.refreshSTSTokenInterval, 'ms'))) return
    }

    const response = await fetchToken(bucket)
    console.log(response)
    const { data } = response
    this.alioss.region = data.region
    this.alioss.accessKeyId = data.credentials.accessKeyId
    this.alioss.accessKeySecret = data.credentials.accessKeySecret
    this.alioss.stsToken = data.credentials.securityToken
    this.alioss.bucket = data.bucket

    this.client = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: this.alioss.region,

      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: this.alioss.accessKeyId,
      accessKeySecret: this.alioss.accessKeySecret,

      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: this.alioss.stsToken,

      // 填写Bucket名称。
      bucket: this.alioss.bucket,

      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: this.alioss.refreshSTSTokenInterval,

      // https
      secure: true,

      // 刷新临时访问凭证。
      refreshSTSToken: async () => {
        const refreshToken = await fetchToken(this.alioss.bucket)

        return {
          accessKeyId: refreshToken.data.credentials.accessKeyId,
          accessKeySecret: refreshToken.data.credentials.accessKeySecret,
          stsToken: refreshToken.data.credentials.securityToken,
        }
      },
    })
  }

  fetchDownloadLink(fileKey, fileName, isDownload = false) {
    if (!fileKey) return ''

    /* 设置Content-Disposition头是为了以attachment形式打开文件链接从而下载文件，但似乎没什么用? */
    const response = {
      'content-disposition': `inline; filename=${encodeURIComponent(fileName || fileKey)}`,
    }

    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    return this.client.signatureUrl(fileKey, {
      response,
      expires: 14400,
    })
  }
}
