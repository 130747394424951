import { getCurrentInstance } from '@vue/composition-api'

export function useMessage() {
  const vm = getCurrentInstance().proxy
  const MessageBox = vm.$msgbox
  const message = vm.$message
  const confirm = vm.$confirm
  const alert = vm.$alert
  const loading = vm.$loading
  const previewImage = vm.$previewImage // 预览图片

  return {
    MessageBox,
    message,
    confirm,
    alert,
    loading,
    previewImage,
  }
}
