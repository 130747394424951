<script>
import { computed, ref, useSlots, onMounted } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { AliOss } from '@/utils/aliOss'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'SuperTable',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '-',
    },
    emptyText: {
      type: String,
      default: '没有更多数据了',
    },
    borderShow: {
      type: Boolean,
      default: true,
    },
    rowConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // 过滤隐藏数据
    const tableColumns = computed(() => props.columns.filter(item => !item.hidden))
    const { isDark } = useAppConfig()
    const slots = useSlots()
    const slotsKeys = Object.keys(slots)
    const { previewImage } = useMessage()
    const tableRef = ref()
    const configRow = computed(() => {
      const data = { isCurrent: false, isHover: true }
      if (typeof props.rowConfig === 'object') {
        return Object.assign(data, props.rowConfig)
      }

      return data
    })
    function reloadData() {
      tableRef.value.reloadData(props.data)
    }

    onMounted(() => {
      const timer = setTimeout(() => {
        reloadData()
        clearTimeout(timer)
      }, 2000)
    })

    // 获取文件名
    function getFileName(url) {
      return url.split('/').pop()
    }

    function openLink(item) {
      const oss = new AliOss()
      oss.init(item.bucketName).then(async () => {
        const link = await oss.fetchDownloadLink(item.key, getFileName(item.key))

        const fileType = item.key.match(/\.([^.]+)$/)[1]

        // 图片类直接预览
        if (['png', 'jpg', 'jpeg', 'gif'].includes(fileType)) {
          previewImage(link)

          return false
        }

        window.open(link, '_blank')
      })
    }

    return {
      tableColumns,
      slotsKeys,
      isDark,
      tableRef,
      configRow,
      reloadData,
      getFileName,
      openLink,
    }
  },
}
</script>

<template>
  <vxe-table
    ref="tableRef"
    :class="[borderShow ? 'border-show' : '']"
    :data="data"
    :loading-config="{icon: 'vxe-icon-indicator roll', text: '加载中...'}"
    :row-config="configRow"
    max-height="300px"
    show-overflow
    v-bind="$attrs"
    style="width: 100%"
    v-on="$listeners"
  >
    <template v-for="item in tableColumns">
      <vxe-column
        :key="item.value"
        :align="item.align || 'left'"
        :field="item.props || item.value"
        :title="item.label || item.text"
        min-width="100px"
        show-overflow
        show-header-overflow
        v-bind="item"
      >
        <template v-if="item.isOss" #default="{ row }">
          <div v-for="val in row[item.props || item.value]" :key="val.key" class="download-link" @click="openLink(val)">
            {{ getFileName(val.key || '') }}
          </div>
        </template>
        <template v-else-if="!item.type && slotsKeys.includes(item.props || item.value)" #default="{ row, rowIndex }">
          <slot :column="item" :index="rowIndex" :name="item.props || item.value" :row="row" />
        </template>
      </vxe-column>
    </template>
  </vxe-table>
</template>
<style lang='scss' scoped>
::v-deep .vxe-table--header-wrapper {
  background: #FAFAFA;
}

</style>

<style lang="scss" scoped>
/*滚动条整体部分*/
.darkScrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.darkScrollbar ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}
/*滚动条里面的小方块，能向上向下移动*/
.darkScrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.darkScrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(168, 168, 168, 0.52);
}
.darkScrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.darkScrollbar ::-webkit-scrollbar-corner {
  background-color: #FFFFFF;
}
</style>
