<script>
import { isJSON, useRouter } from '@core/utils'
import { agreeFlow, getProcessDetail, refuseFlow } from '@/views/workFlow/service'
import { computed, reactive, ref } from '@vue/composition-api'
import Steps from '@/components/Steps/index.vue'
import { isArray, isObject } from 'xe-utils'
import { toastFail } from '@core/utils/prompt'
import SuperTable from '@/components/SuperTable.vue'
import { AliOss } from '@/utils/aliOss'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'Details',
  components: { SuperTable, Steps },
  setup() {
    const { route, router } = useRouter()
    const { id, type, taskId } = route.value.query
    const formData = reactive({
      processName: '', // 流程名称
      processNodes: [], // 流程节点
      processStatus: 1, // 流程整体状态
      formList: [],
      taskId: '', // 任务id
      auditFlag: false,
    })
    const show = ref(false)
    const opinion = ref('')
    const approvalType = ref('ok')
    const ssoUser = localStorage.getItem('ssoUser') ? JSON.parse(localStorage.ssoUser) : {}
    const userId = computed(() => ssoUser?.userId) // 用户id
    const name = computed(() => ssoUser?.nickname) // 用户名
    const { previewImage } = useMessage()

    // 整体表单映射
    function stateMap(status) {
      const data = {
        1: '审批中',
        2: '已终止',
        3: '已取消',
        4: '已完成',
      }

      return data[status]
    }

    // 链表转换数组
    function linkToArr(node) {
      const arr = []
      let currentNode = node
      const mapA = {
        APPROVAL: '审批',
        HANDLER: '办理',
        CC: '抄送',
        ROOT: '提交审批',
      }

      // 是否存在正在处理
      let isHas = false

      // 非空判断
      while (currentNode.children) {
        const nod = {
          userList: (currentNode.props.assignedUser).map(item => ({
            ...item,
            time: item.processTime,
            opinion: item.processComment,
          })),
          type: mapA[currentNode.type],
          status: Number(currentNode.props.businessStatus),
          icon: '',
        }

        if (nod.status === 1 && isHas) nod.icon = 'wait'
        if (nod.status === 1 && !isHas) {
          nod.icon = 'loading'
          isHas = true
        }
        if (nod.status === 2) nod.icon = 'wait'
        if (nod.status === 3) nod.icon = 'no'
        if (nod.status === 4) nod.icon = 'ok'

        arr.push(nod)
        currentNode = currentNode.children
      }

      return arr
    }

    // 提取表格表头
    function getTableHeader(data = []) {
      if (isArray(data)) {
        const header = []
        data.forEach(item => {
          const { name } = item
          header.push({ text: item.title, value: item.id, isOss: ['ImageUpload', 'FileUpload'].includes(name) })
        })

        return header
      }

      return []
    }

    // 初始化数据
    function init() {
      const data = {
        currentUserInfo: {
          id: userId.value,
          name: name.value,
        },
        processInstanceId: id,
        taskId,
      }
      getProcessDetail(data).then(({ result }) => {
        const { processTemplates } = result // 获取表单数据
        const form = result.formData // 表单数据
        const process = JSON.parse(processTemplates.process) // 流程数据
        const formTemplate = JSON.parse(processTemplates.formItems) // 表单模板
        formData.processName = `${process.props.assignedUser[0].name}提交的${processTemplates.templateName}`
        formData.processNodes = linkToArr(process) // 流程节点
        formData.formList = formTemplate.map(item => ({
          label: item.title,
          isFile: ['ImageUpload', 'FileUpload'].includes(item.name),
          isArray: Array.isArray(form[item.id]),
          isTable: item.name === 'TableList',
          value: isJSON(form[item.id]) ? JSON.parse(form[item.id]).join() : form[item.id],
          tableHeader: item.name === 'TableList' ? getTableHeader(item.props.columns) : [],
        })).filter(item => item.value)
        formData.taskId = id
        formData.auditFlag = result.auditFlag
        formData.processStatus = Number(result.processStatus)
      })
    }

    init()

    // 返回
    function backPage() {
      router.back()
    }

    // 拒绝
    function rejectFlow() {
      approvalType.value = 'no'
      opinion.value = ''
      show.value = true
    }
    function resolveFlow() {
      approvalType.value = 'ok'
      opinion.value = ''
      show.value = true
    }

    // 审批
    function approvalFlow() {
      const typeMap = {
        no: refuseFlow,
        ok: agreeFlow,
      }
      const data = {
        currentUserInfo: {
          id: userId.value,
          name: name.value,
        },
        processInstanceId: id,
        comment: (opinion.value || '').trim(),
        taskId,
      }

      typeMap[approvalType.value](data).then(({ status }) => {
        if (status === 200) {
          router.back()
        }
      }).catch(error => {
        toastFail(error.response.data.message)
      })
    }

    function isArrayData(val) {
      return isArray(val)
    }

    function isLink(str) {
      const re = /(https?:\/\/[^\s]+)/g

      return re.test(str)
    }

    function jump(item) {
      if (isLink(item)) {
        window.open(item, '_blank')
      }
    }

    // 获取文件名
    function getFileName(url) {
      return url.split('/').pop()
    }

    function openLink(item) {
      const oss = new AliOss()
      oss.init(item.bucketName).then(async () => {
        const link = await oss.fetchDownloadLink(item.key, getFileName(item.key))

        const fileType = item.key.match(/\.([^.]+)$/)[1]

        // 图片类直接预览
        if (['png', 'jpg', 'jpeg', 'gif'].includes(fileType)) {
          previewImage(link)

          return false
        }

        window.open(link, '_blank')
      })
    }

    // 判断数据keys中是否含有bucketName这个key
    function isBucketName(obj) {
      return Object.keys(obj).includes('bucketName')
    }

    return {
      show,
      type,
      formData,
      opinion,
      jump,
      stateMap,
      backPage,
      rejectFlow,
      resolveFlow,
      approvalFlow,
      isArrayData,
      isBucketName,
      openLink,
      isObject,
      getFileName,
    }
  },
}
</script>

<template>
  <div class="details-view">
    <div class="details-view-title mb16">
      <div
        :class="{ success: formData.processStatus === 4, loading: formData.processStatus === 1, error: [2, 3].includes(formData.processStatus) }"
        class="details-view-title-status"
      >
        {{ stateMap(formData.processStatus) }}
      </div>
      <div class="details-view-title-text">{{ formData.processName }}</div>
    </div>
    <div class="details-view-body">
      <div class="details-view-body-left">
        <div class="details-view-body-left-title">表单信息</div>
        <div class="table-body">
          <div class="details-table mt12">
            <div v-for="(item, index) in formData.formList" :key="index" class="details-table-item">
              <div class="details-table-item-label">{{ item.label }}</div>
              <template v-if="item.isTable">
                <div class="details-table-item-value details-table-item-value-tow">
                  <SuperTable :columns="item.tableHeader" :data="item.value" style="width: 100%" />
                </div>
              </template>
              <template v-else-if="isArrayData(item.value) && item.label.indexOf('多文件') !== -1">
                <div class="details-table-item-value">
                  <span v-for="(val, indexx) in item.value" :key="indexx" class="details-table-item-value-item" @click="jump(val)">
                    {{ val }}
                  </span>
                </div>
              </template>
              <template v-else-if="isArrayData(item.value)">
                <div class="details-table-item-value">
                  <template v-for="(val, indexx) in item.value">
                    <span v-if="!isObject(val)" :key="indexx" class="details-table-item-value-item" @click="jump(val)">
                      {{ val }}
                    </span>
                    <span v-else-if="isBucketName(val)" :key="indexx" class="download-link" @click="openLink(val)">
                      {{ getFileName(val.key || '') }}
                    </span>
                    <span v-else :key="indexx" class="details-table-item-value-item">
                      {{ val }}
                    </span>
                  </template>
                </div>
              </template>
              <div v-else class="details-table-item-value">{{ item.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="details-view-body-right">
        <div class="details-view-body-right-title p20">流程信息</div>
        <!-- 流程节点 -->
        <steps :list="formData.processNodes" class="pl20 pr20 details-view-body-right-steps" />
        <div class="review-button-group">
          <v-divider class="mt0 mb14" />
          <div class="review-button-group-body px20">
            <v-btn outlined small @click="backPage">返回</v-btn>
            <!-- 非查看并且有任务ID -->
            <template v-if="type !== 'see' && formData.auditFlag">
              <v-btn color="primary" outlined small @click="rejectFlow">拒绝</v-btn>
              <v-btn color="primary" small @click="resolveFlow">同意</v-btn>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- 审批意见 -->
    <v-dialog
      :persistent="show"
      :value="show"
      attach="#app"
      scrollable
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card>
        <v-toolbar height="60" style="box-shadow: none;">
          <span class="fs20 dialog-title">审批意见</span>
          <v-spacer />
          <v-icon @click="show = false">
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-divider class="m0" />
        <v-card-text class="p30" style="height: 300px">
          <div class="mb12 fs14" style="color: black">备注</div>
          <div>
            <v-textarea
              v-model="opinion"
              clearable
              counter="500"
              maxlength="500"
              outlined
            />
          </div>

          <div class="f-r mt15" style="gap: 8px">
            <v-btn color="primary" outlined @click="show = false">取消</v-btn>
            <v-btn color="primary" @click="approvalFlow">确认</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang='scss' scoped>
.details-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    display: flex;
    gap: 12px;
    height: 32px;
    font-size: 24px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: rgba(22,19,19,0.85);
    line-height: 33px;

    &-status {
      padding: 0 12px;
      color: #fff;
      font-size: 14px;
      border-radius: 6px;
      text-align: center;
    }
  }

  &-body {
    display: flex;
    gap: 12px;
    height: 100%;

    &-left {
      border-radius: 8px;
      padding: 20px;
      flex: auto;
      width: calc(100% - 420px);
      height: 100%;
      background-color: #fff;

      &-title {
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
      }
    }

    &-right {
      border-radius: 8px;
      width: 420px;
      height: calc(100%);
      background-color: #fff;

      &-title {
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 24px;
      }

      &-steps {
        height: calc(100% - 120px);
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }
}

.success {
  background: #30A46C;
}
.loading {
  background-color: #FF9123;
}
.error {
  background-color: #E5484D;
}

.review-button-group {
  height: 60px;

  &-body {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.details-table {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1.5px solid #E7ECF0;

  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    &-label {
      flex-shrink: 0;
      width: 140px;
      padding: 5px 12px;
      background: #F7F9FB;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-value {
      border-left: 1px solid #E7ECF0;
      padding: 15px 20px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.85);
      line-height: 22px;
      word-break:break-all;

      &-item {
        background-color: #E7ECF0;
        padding: 5px;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
        display: block;
        margin-right: 10px;
      }
    }

    &-value-tow {
      overflow: hidden;
      overflow-x: auto;
      padding: 8px !important;
    }
  }

  &-item + &-item {
    border-top: 1.5px solid #E7ECF0;
  }
}

.table-body {
  height: calc(100% - 25px);
  overflow: hidden;
  overflow-y: auto;
}

.dialog-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #000000;
}
</style>
